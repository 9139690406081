import {request} from './request'
import Cookies from 'js-cookie'

const maker_id=Cookies.get("maker_id")
export function getLogos() {
    var maker_id = Cookies.get("maker_id");
    return request({url:'/profile/getProfiles.php',params:{maker_id}});
}
export function getProfile(maker_id) {
    return request({url:'/profile/getProfile.php',params:{maker_id}})
}
export function getMedias(maker_id,page,rows_per_page=10) {
    return request({url:'/media/getMedias.php',params:{page,maker_id,rows_per_page}})
}
export function getPresses(maker_id,page,rows_per_page=10) {
    return request({url:'/press/getPresses.php', params:{page,maker_id,rows_per_page}})
}
export function getPressDetail(maker_id,press_id) {
    return request({url:'/press/getPress.php', params:{press_id,maker_id}})
}
export function getProducts(maker_id,page,rows_per_page=10) {
    return request({
        url:'/product/getProducts.php',
        params:{maker_id,page,rows_per_page},
    })
}
