<template>
    <div>
        <a-page-header :title="$t('store.title')" class="header_title"/>
        <div class="box_content">
            <div class="stores" style="display:block;">
                <img src="/img/Amazon.jpg" @click="action_location('https://www.amazon.com/')" style="cursor:pointer"/>
                <img src="/img/ebay.jpg" @click="action_location('https://www.ebay.com/')" style="cursor:pointer"/>
                <img src="/img/shopify.jpg" @click="action_location('https://www.shopify.com/')" style="cursor:pointer"/>
                <img src="/img/walmart.jpg" @click="action_location('https://www.walmart.com/')" style="cursor:pointer"/>
                <img src="/img/htvmall.png" style="cursor:pointer"/>
            </div>
        </div>
        <!--<a-page-header title="Stores"/>
        <div class="stores">
            <img v-for="item in logos" :key='item.maker_id' :src="item.logo" @click="detailBtn(item.maker_id)"/>
        </div>
        -->
    </div>
</template>
 
<script>
import {getLogos} from '@/network/storesrequest'
export default {
    name: 'NodeprojectStores',
    components: {},
    directives: {},
    data() {
        return {
            logos:[],
        };
    },
    created(){
        /*getLogos()
        .then(res=>{
            if(res.header.code==200){
                console.log(res);
                this.logos=res.body.profiles
            }
        });*/
    },
    computed:{
        
    },
    mounted() {
        
    },
    methods: {
        detailBtn(maker_id){
            this.$router.push({path:'/stores/Detail',query:{maker_id:maker_id}})
        },
        action_location(url){
            window.open(url,'_blank')
        }
    },
};
</script>

<style  scoped>
.stores{
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}
.stores img{
    width: 100px;
    margin: 5px;
    border: 1px solid #e8e8e8;
    border-radius: 2px;
}
</style>